<template>
  <div
    :class="['status', badgeStyle]"
    :style="{ height: height + 'px', width: 'auto' }"
  >
    {{ badgeText }}
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  props: {
    badgeText: {
      type: String,
      required: false,
      default: "Terminé"
    },
    badgeStyle: {
      type: String,
      required: false,
      default: "green",
      validator(value) {
        return ["green", "blue", "red", "grey", "yellow"].includes(value);
      }
    },
    height: {
      type: Number,
      required: false,
      default: 17
    }
  }
};
</script>

<style scoped>
.status {
  flex-grow: 0;
  font-family: Poppins;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 11px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.75;
  letter-spacing: -0.04px;
  text-align: center;
}
.grey {
  background-color: #e0e7ee;
  color: #6b829a;
}
.blue {
  background-color: #ebf0ff;
  color: #6890f9;
}
.yellow {
  background-color: #ffe3d1;
  color: #662f0a;
}
.green {
  background-color: #e9fae4;
  color: #4ac112;
}
.red {
  background-color: #fff1f1;
  color: #ff1700;
}
</style>
