<template>
  <div class="giga-empty_state" :class="'giga-'+module+'-empty_state'">
    <div class="giga-empty_state_content">
      <h3 v-if="title" class="giga-empty_state_title">{{title}}</h3>
      <p v-if="description" class="giga-empty_state_description">{{description}}</p>
      <div v-if="addSimulation" class="giga-button">
        <b-button
            v-if="isNotVIEWER"
            class="giga-green_button no-icon"
          :to="{ name: 'AddSimulation' }"
        >
          <span>{{ $t("add_simulation") }}</span>
        </b-button>
      </div>
      <div v-if="addUser" class="giga-button">
        <button
        @click="newUser()"
          class="giga-green_button no-icon"
        >
          <span>Ajouter un membre</span>
        </button>
      </div>
      <div v-if="addAgence" class="giga-button">
        <b-button
            @click="newAgence()"
            class="giga-green_button no-icon"
        >
          <span>{{ $t("add_agence") }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {helpers} from "@/shared/helper";

export default {
  name: "emptyState",
  props: {
    module: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    addSimulation: {
        type: Boolean,
        default: false
    },
    addAgence: {
      type: Boolean,
      default: false
    },
    addUser:{
        type: Boolean,
        default: false
    },
    newUserModal:{
        type: Function
    },
    newAgenceModal:{
      type: Function
    }
  },
  methods:{
    newUser(){
      this.$emit('newUserModal')
    },
    newAgence(){
      this.$emit('newAgenceModal')
    },
  },
  computed:{
    isNotVIEWER(){
      return !helpers.isVIEWER();
    }
  }
};
</script>
