var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{key:_vm.index,staticClass:"import-masse-progress",class:_vm.collapseDiv,style:(Object.assign({}, {top: _vm.positionY + '%',
      left: _vm.positionX + '%'},
      _vm.boxShadowStyle)),on:{"mousedown":_vm.startDrag}},[(!_vm.collapsed)?_c('div',{staticClass:"header"},[_c('span',[_vm._v("Import en masse")]),_c('img',{staticClass:"custom-icon",attrs:{"src":require("@/assets/images/collapse.svg"),"alt":""},on:{"click":_vm.collapseAction}})]):_vm._e(),(!_vm.collapsed)?_c('div',{staticClass:"title"},[_c('span',{staticClass:"simulation-name",on:{"click":function($event){return _vm.goToSimulation(_vm.importDetail.clientReferenceId)}}},[_vm._v(_vm._s(_vm.importDetail.clientRef))]),_c('img',{staticClass:"custom-icon",attrs:{"src":require("@/assets/images/fluent-open.svg"),"alt":""},on:{"click":function($event){return _vm.goToSimulation(_vm.importDetail.clientReferenceId)}}}),_c('status-badge',{attrs:{"badgeText":_vm._f("importStatusText")(_vm.importDetail.status),"badgeStyle":_vm._f("importStatusStyle")(_vm.importDetail.status)}})],1):_vm._e(),(!_vm.collapsed)?_c('div',{staticClass:"preview-counts"},[_c('status-indicator',{attrs:{"statusText":((_vm.importDetail.importedOperationsCount) + " Importées"),"statusColor":"green","sizeType":"small","textColor":"#132d48"}}),_c('status-indicator',{attrs:{"statusText":((_vm.importDetail.rejectedOperations) + " Rejectées"),"statusColor":"red","sizeType":"small","textColor":"#132d48"}})],1):_vm._e(),_c('div',{staticClass:"progress-div"},[_c('div',{staticClass:"progress-bar-container progress c-progress"},[_c('div',{class:{
            'bg-danger': _vm.importDetail.status === 'FAILED',
            'in-progress-bg':
              _vm.importDetail.status === 'PENDING' ||
              _vm.importDetail.status === 'IN_PROGRESS',
            'progress-bar': true,
            'progress-bar-animated': true
          },style:({
            width: _vm.pourcentageImport(_vm.importDetail) + '%' + ' !important'
          }),attrs:{"role":"progressbar"}})]),_c('span',{staticClass:"pourcentage",class:{ 'failed-color': _vm.importDetail.status === 'FAILED' }},[_vm._v(_vm._s(_vm.pourcentageImport(_vm.importDetail))+"%")]),(_vm.collapsed)?_c('img',{staticClass:"collapse-icon",attrs:{"src":require("@/assets/images/expand.svg"),"alt":""},on:{"click":_vm.collapseAction}}):_vm._e(),(
          _vm.importDetail.status === 'PARTIALLY_DONE' ||
            _vm.importDetail.status === 'DONE'
        )?_c('img',{staticClass:"success-icon",attrs:{"src":require("@/assets/images/task-success.svg"),"alt":""}}):_vm._e(),_c('img',{staticClass:"close-icon",attrs:{"src":require("@/assets/images/close.svg"),"alt":""},on:{"click":function($event){return _vm.closeImportIndicator(_vm.importDetail)}}})]),(!_vm.collapsed)?_c('div',{staticClass:"ratio-imported-operations"},[_c('span',{staticClass:"success"},[_vm._v(_vm._s(_vm.importDetail.importedOperationsCount)+" / "+_vm._s(_vm.importDetail.totalOperations)+" Operations")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }