<script>
import {Doughnut} from "vue-chartjs";

export default {
  name: "doughnutWidget",
  extends: Doughnut,
  props:{
    labels:{
        type: Object
    },
    values:{
        type: Object
    },
    chartdata: {
      type: Array,
      default: () => []
    }
  },
    data () {
    return {
      gradient: null
    }
  },
  mounted() {
    this.renderChart(
      {
        labels: ['Volume delivré', 'Volume en cours', 'Volume déposé'],
        datasets: [
          {
            backgroundColor: ['#6CBE3E', '#01588B', '#FF6D64'],
            data: this.chartdata,
            borderWidth: 10,
            hoverBorderWidth: 0,
          }
        ]
      },
      { 
        legend: {
          position: 'right',
          align: 'center',
          labels:{
            boxWidth: 20,
            boxHeight: 20,
          }
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              // convert volume from Kwh to MWh
              const volume = Math.floor(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / 1000);
              return Intl.NumberFormat('fr-FR').format(volume) + ' MWh';
            }
          }
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 25,
                bottom: 0
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        cutout: '90%',
        radius: '90%',
        plugins: {
          legend: {
            display: false,
            position: 'right',
            align: 'end',
          }
        },
        elements: {
          arc: {
            spacing: 50,
            borderRadius: 15
          }
        }
      }
    );
  },
};
</script>