<template>
  <div>
    <div
      :key="index"
      class="import-masse-progress"
      :class="collapseDiv"
      :style="{
        top: positionY + '%',
        left: positionX + '%',
        ...boxShadowStyle
      }"
      @mousedown="startDrag"
    >
      <div class="header" v-if="!collapsed">
        <span>Import en masse</span>
        <img
          class="custom-icon"
          src="@/assets/images/collapse.svg"
          alt=""
          @click="collapseAction"
        />
      </div>
      <div class="title" v-if="!collapsed">
        <span
          class="simulation-name"
          @click="goToSimulation(importDetail.clientReferenceId)"
          >{{ importDetail.clientRef }}</span
        >
        <img
          class="custom-icon"
          src="@/assets/images/fluent-open.svg"
          alt=""
          @click="goToSimulation(importDetail.clientReferenceId)"
        />
        <status-badge
          :badgeText="importDetail.status | importStatusText"
          :badgeStyle="importDetail.status | importStatusStyle"
        ></status-badge>
      </div>
      <div class="preview-counts" v-if="!collapsed">
        <status-indicator
          :statusText="`${importDetail.importedOperationsCount} Importées`"
          statusColor="green"
          sizeType="small"
          textColor="#132d48"
        />
        <status-indicator
          :statusText="`${importDetail.rejectedOperations} Rejectées`"
          statusColor="red"
          sizeType="small"
          textColor="#132d48"
        />
      </div>
      <div class="progress-div">
        <div class="progress-bar-container progress c-progress">
          <div
            :class="{
              'bg-danger': importDetail.status === 'FAILED',
              'in-progress-bg':
                importDetail.status === 'PENDING' ||
                importDetail.status === 'IN_PROGRESS',
              'progress-bar': true,
              'progress-bar-animated': true
            }"
            role="progressbar"
            :style="{
              width: pourcentageImport(importDetail) + '%' + ' !important'
            }"
          ></div>
        </div>
        <span
          class="pourcentage"
          :class="{ 'failed-color': importDetail.status === 'FAILED' }"
          >{{ pourcentageImport(importDetail) }}%</span
        >
        <img
          class="collapse-icon"
          src="@/assets/images/expand.svg"
          alt=""
          @click="collapseAction"
          v-if="collapsed"
        />
        <img
          class="success-icon"
          src="@/assets/images/task-success.svg"
          alt=""
          v-if="
            importDetail.status === 'PARTIALLY_DONE' ||
              importDetail.status === 'DONE'
          "
        />
        <img
          class="close-icon"
          src="@/assets/images/close.svg"
          alt=""
          @click="closeImportIndicator(importDetail)"
        />
      </div>
      <div class="ratio-imported-operations" v-if="!collapsed">
        <span class="success"
          >{{ importDetail.importedOperationsCount }} /
          {{ importDetail.totalOperations }} Operations</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@/components/static/shared/StatusBadge.vue";
import StatusIndicator from "@/components/static/shared/StatusIndicator.vue";
import { importService } from "@/views/Simulations/services/import.service";
import eventBus from "@/shared/event.bus";
import { number } from "sockjs-client/lib/utils/random";
import statusBadge from "@/components/static/shared/StatusBadge.vue";

export default {
  name: "ImportInMassIndicator",
  components: { StatusIndicator, StatusBadge },
  created() {
    console.log(
      this.importDetail.clientRef,
      this.statusMapping(this.importDetail.status)
    );
  },
  data() {
    return {
      importsDetails: [],
      intervalId: null,
      collapsed: false,
      collapseDiv: "expanded-div",
      isDragging: false,
      positionX: 76.5,
      positionY: 74 - this.index * 22,
      offsetX: 0,
      offsetY: 0
    };
  },
  props: {
    index: {
      type: number,
      required: false
    },
    importDetail: {
      type: Object,
      required: false
    }
  },
  computed: {
    statusBadge() {
      return statusBadge;
    },
    boxShadowStyle() {
      if (this.importDetail.status === "FAILED") {
        return {
          boxShadow: "0px 10px 25px -2px rgba(255, 23, 0, 0.4)",
          border: "1px solid rgba(255, 23, 0, 0.2)"
        };
      } else {
        return {
          boxShadow: "0px 18px 35px -6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.04)"
        };
      }
    }
  },
  methods: {
    startDrag(event) {
      this.isDragging = true;

      const parentWidth = window.innerWidth;
      const parentHeight = window.innerHeight;

      const pixelX = (this.positionX / 100) * parentWidth;
      const pixelY = (this.positionY / 100) * parentHeight;

      this.offsetX = event.clientX - pixelX;
      this.offsetY = event.clientY - pixelY;

      document.addEventListener("mousemove", this.onMouseMove);
      document.addEventListener("mouseup", this.stopDrag);
    },
    onMouseMove(event) {
      if (this.isDragging) {
        const parentWidth = window.innerWidth;
        const parentHeight = window.innerHeight;

        const newPixelX = event.clientX - this.offsetX;
        const newPixelY = event.clientY - this.offsetY;

        this.positionX = (newPixelX / parentWidth) * 100;
        this.positionY = (newPixelY / parentHeight) * 100;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.onMouseMove);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    collapseAction() {
      if (this.collapsed) {
        this.collapseDiv = "expanded-div";
        setTimeout(() => {
          this.collapsed = !this.collapsed;
        }, 50);
      } else {
        this.collapseDiv = "collapsed-div";
        this.collapsed = !this.collapsed;
      }
    },
    closeImportIndicator(importDetail) {
      this.importsDetails = this.importsDetails.filter(
        i => i.id !== importDetail.id
      );
      this.$emit("close-import", importDetail.id);
    },
    pourcentageImport(importDetail) {
      if (!importDetail) {
        return 0;
      }
      const {
        importedOperationsCount,
        rejectedOperations,
        totalOperations
      } = importDetail;
      if (totalOperations === 0) {
        return 0;
      }
      return (
        ((importedOperationsCount + rejectedOperations) / totalOperations) *
        100
      ).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },
    goToSimulation(idSimulation) {
      this.$router.push(`/simulations/detail/${idSimulation}/overview`);
    }
  },
  beforeDestroy() {}
};
</script>

<style lang="scss">
.expanded-div {
  height: 172.7px;
  transition: height 200ms;
}
.collapsed-div {
  height: 65.7px;
  transition: height 200ms;
}
.import-masse-progress {
  width: 395px;
  flex-grow: 0;
  padding: 11px 17px 8px 23px;
  border-radius: 6px;
  background-color: #fff;
  position: absolute;
  font-family: "Arial", sans-serif;
  z-index: 100;

  .header {
    display: flex;
    justify-content: space-between;
    span {
      height: 30px;
      flex-grow: 0;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.14;
      letter-spacing: -0.07px;
      text-align: left;
      color: #132d48;
    }
    img {
      width: 20px;
      cursor: pointer;
    }
  }
  .preview-counts {
    display: flex;
    .status-indicator:first-child {
      margin-right: 12px;
    }
  }

  .progress-bar-container {
    position: relative;
    background-color: #f0f0f0;
    width: 80%;
    border-radius: 5px;
    height: 8px;
    overflow: hidden;
  }

  .progress-div {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .pourcentage {
      width: 41px;
      margin-right: 22px;
      margin-left: 10px;
      flex-grow: 0;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: -0.06px;
      text-align: center;
      color: #c8d4df;
    }
    .close-icon {
      width: 13px;
      flex-grow: 0;
      margin: 7px 3px 7px 22px;
      cursor: pointer;
    }
    .success-icon {
      flex-grow: 0;
      margin: 7px 3px 7px 22px;
      cursor: pointer;
    }
    .collapse-icon {
      cursor: pointer;
    }
  }
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .simulation-name {
      cursor: pointer;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.14;
      letter-spacing: -0.07px;
      text-align: left;
      color: #132d48;
    }
    img {
      margin: 0 18.8px 0 6.3px;
    }
    .custom-icon {
      width: 5%;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .ratio-imported-operations {
    display: flex;
    margin: 10px 0 10px 0;
    .success {
      flex-grow: 0;
      font-family: Poppins;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: -0.06px;
      text-align: left;
      color: #c8d4df;
      margin-right: 10px;
    }
    .failed {
      flex-grow: 0;
      font-family: Poppins;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: -0.06px;
      text-align: left;
      color: #ff6d64;
    }
  }
  .failed-color {
    color: #ff1700 !important;
  }
  .failed-bg {
    background-color: #ff1700 !important;
  }
  .in-progress-bg {
    background-color: #6890f9 !important;
  }
}
</style>
