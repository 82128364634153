<template>
  <div class="status-indicator" :style="{ color: textColor }">
    <span :class="['dot', pointSize, statusColor]"></span>
    <span :class="textSize">{{ statusText }}</span>
  </div>
</template>

<script>
export default {
  name: "StatusIndicator",
  props: {
    statusText: {
      type: String,
      required: true,
      default: "Opérations prêtes"
    },
    statusColor: {
      type: String,
      required: true,
      default: "green",
      validator(value) {
        return ["green", "red"].includes(value);
      }
    },
    sizeType: {
      type: String,
      required: false,
      default: "normal",
      validator(value) {
        return ["large", "normal", "small"].includes(value);
      }
    },
    textColor: {
      type: String,
      required: false,
      default: "#143a5a"
    }
  },
  computed: {
    pointSize() {
      return {
        large: "point-large",
        normal: "point-normal",
        small: "point-small"
      }[this.sizeType];
    },
    textSize() {
      return {
        large: "text-large",
        normal: "text-normal",
        small: "text-small"
      }[this.sizeType];
    }
  }
};
</script>

<style>
.status-indicator {
  display: flex;
  align-items: center;
  font-family: Inter;
}

.dot {
  border-radius: 50%;
  margin-right: 6px;
}

.point-large {
  width: 10px;
  height: 10px;
}

.point-normal {
  width: 8px;
  height: 8px;
}

.point-small {
  width: 6px;
  height: 6px;
}

.text-large {
  font-size: 15px;
  font-weight: 600;
}

.text-normal {
  font-size: 13px;
  font-weight: 500;
}

.text-small {
  font-size: 11px;
  font-weight: 600;
}

.green {
  background-color: #6cbe3e;
}

.red {
  background-color: #ff4f4f;
}
</style>
