import { render, staticRenderFns } from "./pageLoader.vue?vue&type=template&id=3338c97d&"
import script from "./pageLoader.vue?vue&type=script&lang=js&"
export * from "./pageLoader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports