<template>
    <div class="giga-loader" :class="[{'pageHasActions': hasActions}, {'pageHasHeader': hasHeader}]">
       <lottie-player src="https://assets10.lottiefiles.com/private_files/lf30_yxonmdwh.json" background="transparent" speed="1.8" style="width: 100px; height: 100px;" loop autoplay></lottie-player>
    </div>
</template>

<script>

import "@lottiefiles/lottie-player";

export default {
    name: 'pageLoader',
    props:{
        hasActions:{
            type: Boolean,
            default: false
        },
        hasHeader:{
            type: Boolean,
            default: false
        }
    }
}
</script>
